.nav-tabs{
    background:"transparent";
}

.tab-pane{
    background-color: #ffffff;
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
}

li.nav-item{
    background: #00853f;
}

button{
    color: white !important;
}

.nav-tabs .nav-link{
    border-radius: 0px !important;
}

.card{
    background: #ffffff !important;
    width: 18rem;
    height: 28rem;
}

.react-multi-carousel-item--active {
    text-align: -webkit-center;
}

.react-multi-carousel-track {
    align-items: center;
}

.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    z-index: 1000;
    border: 0;
    border-radius: 7px !important;
    background: #18CF93 !important;
    min-width: 35px !important;
    min-height: 35px !important;
    opacity: 1;
    cursor: pointer;
    margin-left: -36px;
    margin-right: -36px;
}

.react-multiple-carousel__arrow:hover{
    background-color: #068a5e !important;
}

.box-price-card{
    width: 100px;
    height: 30px;
    background-color: #00853f;
    color: #ffffff; 
    margin-top:-30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.title-block{
    text-align: left;
    font: normal normal 300 48px/56px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    /* padding: 0 0 0 40px; */
}

.text-block{
    margin-top:30px;
    text-align: left;
    font: normal normal 300 16px/22px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
}

.title-multiple-card{
    /* color:#000000;
    font-size: 16px;
    font-weight: bold; */
    text-align: left;
    font: normal normal normal 25px/24px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    margin-top: 10px;
}

.text-multiple-card{
    /* color: #969596;
    font-size: 12px;
    font-style: italic; */
    text-align: left;
    font: normal normal 300 18px/24px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
}

.margin-top-card{
    margin-top: 0px
}

.btn-search-selected{
    background-color: #ffffff !important;
    color: #00853f !important;
}

.btn-search-not-selected{
    background-color: #00853f !important;
    color: #ffffff !important;
}

.btn-search-not-selected:hover{
    background-color: #18CF93 !important;
}

.btnSelHome{
    height: 55px;
    width: 250px;
    border: 0 !important;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 12px !important; */
    font: normal normal 600 15px/18px 'proxima-nova', sans-serif;
    letter-spacing: 2.1px;
    color: #00853F;
    text-transform: uppercase;
    opacity: 1;
}

.labelSelHome{
    font-size: 10px;
}

.selHome{
    background-color: #f1f1f1 !important;
    border-radius: 0px !important;
    border-color: #cccccc !important;
    color: #00000050 !important;
    font-size: 12px !important;
}

/* .selHome:active{
    background-color: red !important;
} */

.gradientHome{
    width: 100%;
    margin-top: 100px;
    height: 70vh;
    background: transparent linear-gradient(359deg, #E3F5ED 50%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    background-size: 100%;
    background-repeat: no-repeat;
    
}
.containerBgHome{
    width: 100%;
    height: 70vh;
    background: url('../../images/backgroundHome.png') no-repeat;
    background-position: bottom;
    background-size: cover;
}

.searchBox{
    transform: translateY(-10vh)
}
.boxTitleHome{
    width: 100%;
    padding-left: 217px;
}

.titleHome{
    text-align: left;
    font: normal normal 200 58px/68px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
}

.subtitleHome{
    text-align: left;
    font: normal normal 300 22px/27px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
    margin-top: 15px;
}

.labelSelect{
    text-align: left;
    font: normal normal normal 12px/15px 'proxima-nova', sans-serif;
    letter-spacing: 1.68px;
    color: #3D3A3C;
    text-transform: uppercase;
    opacity: 0.86;
    margin-bottom: 20px;
}

.btnSearch{
    background-color: #18CF93 !important;
    height: 100%;
    border-color: #18CF93 !important;
    border-radius: 0px !important;
    float: right;
    border-left: 2px solid #00853F !important;
}
.btnSearch:hover{
    background-color: #00853F !important;
    border-color: #00853F !important;

}

.rowSliderApp{
    width: 100%;
    padding: 0 50px 50px 50px;
    display: flex;
    background-color: #F6F6F6;
}
.rowSliderApp .react-multi-carousel-list {
    height: 90vh;
}
.containerPres{
    width: auto;
    padding: 100px;
}

.colFirstApp{
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerGlobalActivity{
    width: 100%;
    display: flex;
}
.containerActivityLeft{
    width: 33.3%;
    padding: 40px;
}

.boxActivity{
    color: red;
}

.textActivity{
    text-align: left;
    font: normal normal 300 16px/22px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
}

.iconSearch{
    background: url('../../images/icons/icona-cerca.svg');
    background-repeat: no-repeat;
    background-position: center center;
    height: 24px;
}

.iconHomeServizi{
    background: url('../../images/icons/icona-servizi-intermediazione.svg');
    background-repeat: no-repeat;
    background-position: center center;
    height: 39px;
    width: 32px;
}

.iconHomeIndagini{
    background: url('../../images/icons/icona-indagini.svg');
    background-repeat: no-repeat;
    background-position: center center;
    height: 39px;
    width: 35px;
}

.iconHomeValutazioniImmobiliari{
    background: url('../../images/icons/icona-valutazioni.svg');
    background-repeat: no-repeat;
    background-position: center center;
    height: 39px;
    width: 36px;
}

.iconHomeAssistenza{
    background: url('../../images/icons/icona-assistenza.svg');
    background-repeat: no-repeat;
    background-position: center center;
    height: 39px;
    width: 22px;
}

.iconHomeHomeStaging{
    background: url('../../images/icons/icona-home-staging.svg');
    background-repeat: no-repeat;
    background-position: center center;
    height: 39px;
    width: 24px;
}

.colGoogleMap{
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerSelMap{
    width: 100%;
    background: #ffffff;
    max-height: 600px;
    padding: 20px;
    height: 100%;
    overflow: hidden;
}

.boxSelMap{
    width: 100%;
    height: 100%;
}

.btnCloseMap{
    color: #fff;
    background-color: #18cf93!important;
    border-color: #18cf93!important;
    height: 56px;
    width: 56px;
}
.btnCloseMap:hover{
    background-color: #10a573!important;
    border-color: #10a573!important;
}
.ContmainBtnSelMap{
    height: 10%;
    display: flex;
    align-items: end;
    justify-content: center;
}

.tileSelMap{
    font: normal normal 600 12px/15px  'proxima-nova', sans-serif;
    letter-spacing: 1.68px;
    color: #3D3A3C;
    text-transform: uppercase;
    opacity: 0.86;
}

.separatorMap{
    width: 100%;
    height: 0px;
    border: 1px solid #DFDFDF;
    opacity: 1;
    margin: 20px 0;
}

.containerZone{
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #ECECEC;
    border: 1px solid #3A3A3A47;
    height: 43px;
    width: max-content;
    margin: 20px 0px;
}

.containerZoneSel{
    background: #E3F5ED;
    border: 1px solid #00853F26;
    opacity: 1;
    font: normal normal 300 16px/27px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #00853F;
    opacity: 1;
}

.titleZone{
    text-align: left;
    font: normal normal 300 16px/27px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 1;
}

.round {
    position: relative;
    height: 25px;
    margin-right: 15px;
  }
  
  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 25px;
    /* left: 0; */
    /* position: absolute; */
    /* top: 0; */
    width: 25px;
  }
  
  .round label:after {
    border: 2px solid #00853F;
    /* border: 2px solid #ffffff; */
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }
  
  .round input[type="checkbox"] {
    /* visibility: hidden; */
    display: none;
  }
  
  .round input[type="checkbox"]:checked + label {
    background-color: #B6E2C9;
    border-color: #B6E2C9;
  }
  
  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  .containerMap{
    width: auto;
    height: 620px;
    background: #ffffff;
    display: flex;
    padding: 10px;
    box-shadow: 0px 0px 34px #00000029;
  }

  .boxPeopleAgency{
    border: 1px solid #cccccc;
    width: 80% !important;
    display: flex;
    margin: 0 auto;
    background-color: #ffffff;
    align-items: normal;
    padding-left: 0!important;
  }

.imageChisiamo{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

  .containerChiSiamo{
      padding: 100px;
  }

  .boxChiSiamo{
      width: 50%;
  }

  .containerBoxTextActivity{
      width: 100%;
      background-color: #ffffff;
      padding: 50px;
  }

.containerListAct{
    width: 100%;
    background-color: #fafcfb;
    background: url('../../images/Armoniae_esterna01_scontorno.png'), transparent linear-gradient(0deg, #F5F9F7 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 100px;
    height: 700px;
}

.containerInfoAgency{
    width: 100%;
    height: 800px;
}

.elementBkgInfoAgencyTop{
    width: 100%;
    height: 400px;
    background-color: #ffffff;
}
.elementBkgInfoAgencyBottom{
    width: 100%;
    height: 400px;
    background-color: #F3F3F3;
}

.btnDropSelect{
    background-color: #00000090 !important;
    color: #ffffff !important;
    border: none;
    /* display: flex; */
    display: none;
    align-items: center;
    position: absolute;
    padding: 6px;
    right: 0;
  }

  .btnDropSelect:hover{
    background-color: #00853f !important;
  }

  .itemSelect{
      padding: 0 !important;
  }

  .itemSelect:hover .btnDropSelect{
    display: flex;
  }

  .iconMapItem{
    background: url('../../images/icons/icona-comune-mappa.svg');
    background-repeat: no-repeat;
    background-size: 9px 12px;
    height: 12px;
    width: 9px;
    background-position: center center;
    margin: 0 5px;
  }

  .mapContainerStyle{
     height: 600px;
     width: 800px;
    }

    /* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected{
        background-color: red !important;
    } */

    .Mui-selected{
        background: #E3F5ED 0% 0% no-repeat padding-box !important;
    }
.cardFooter{
    background: #F5F9F7 0% 0% no-repeat padding-box;
    display: grid;
    border: none !important;
}

/* .cardFooter:hover{
    background: #E3F5ED 0% 0% no-repeat padding-box;
} */

.itemCardFooter{
    font: normal normal 600 14px/17px 'proxima-nova', sans-serif;
    letter-spacing: 1.96px;
    color: #00853F;
    text-transform: uppercase;
}

.glCardAppartment:hover .cardFooter{
    background: #E3F5ED 0% 0% no-repeat padding-box;
}

.titleCardApartment{
    font: normal normal 300 19px/19px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
}

.containerCardText{
    text-align: left;
}
.containerCardText:last-child {
    margin-bottom: 0;
}

.genCardComp{
    width: 80%;
    /* height: 75%;    */
}
.genCardCom .card{
    height: 80%;
}
.card-img-top{
    border-top-left-radius: calc(0.25rem - 1px)!important;
    border-top-right-radius: calc(0.25rem - 1px)!important;
    /* height: 57%; */
    height: 300px;
    object-fit: cover;
} 
.boxCardApartment{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    width: 33rem;
}
.boxIconCardAppartment{
    width: 33.3%;
    height: auto;
    background: #F5F9F7;
    border: 1px solid #00853F40;
    border-radius: 2px;
    opacity: 1;
    margin-right: 10px;
    padding: 10px;
}

.imgCardMq{
    background: url('../../images/icons/icona-mq.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
}

.imgCardLocali{
    background: url('../../images/icons/icona-locali.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
}

.imgCardPiano{
    background: url('../../images/icons/icona-piano.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
}

.textIconCard{
    font: normal normal 400 16px/14px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    margin: 10px 0 0 0;
}

.containerSlider{
    width: 100%;
    padding: 15px;
    background-color: #ffffff;
    display: flex;
}
.rowPrices{
    width: 50% !important;
}
.prices {
    width: 100%;
    font: normal normal 300 14px/18px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #8B8B8B;
    opacity: 1;
}
.pricesDx{
    text-align: right;
}
.containerSliderComponent{
    width: 100%;
}

.searchDesk{
    display: block;
}
.searchMobile{
    display: none;
}
.btnSearchMobile{
    width: 100% !important;
    height: 50px !important;
}
.inputSliderFilter{
    background-color: #ffffff;
    width: 90% !important;
    height: 29px;
    border: 1px solid #B7B7B7;
    font: normal normal 300 14px/18px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #329B5C;
}

.inputSliderFilter:focus-visible{
    outline: none;
}

.form-check-input{
    cursor: pointer;
    border-radius: 100px !important;
    background-color: #E8E8E8 !important;
    border-color: #E8E8E8 !important;
}

.form-check-input:checked {
    background-color: #5DDBB2;
    border-color: #5DDBB2;
    border-radius: 0px;
}
.form-check-input:focus{
    box-shadow: none !important;
    border: 1px solid rgba(0,0,0,.25);
}

.form-control:focus{
    background-color: #f6f6f6;
    border: none;
    border-bottom: 1px solid #ced4da;
    box-shadow: none;
}

.checkboxList{
    color: #E8E8E8 !important;
}
.checkboxList:checked{
    color: red !important;
}

.input-box {
    display: flex;
    align-items: center;
    max-width: 300px;
    background: #fff;
    border: 1px solid #B7B7B7;
    border-radius: 0px;
    padding-left: 0.5rem;
    overflow: hidden;
    font-family: sans-serif;
    width: 80%;
  }
  
  .input-box .prefix {
    font: normal normal 300 14px/18px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #329B5C;
  }

  .inputSliderFilter::placeholder{
    font: normal normal 300 14px/18px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #329B5C;
}
  
  .input-box input {
    flex-grow: 1;
    font-size: 14px;
    background: #fff;
    border: none;
    outline: none;
    padding: 0.5rem;
  }
  
  .input-box:focus-within {
    border-color: #777;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }

  .labelSearch{
    font: normal normal 600 15px/18px 'proxima-nova', sans-serif;
    letter-spacing: 2.1px;
    color: #FFFFFF;
    text-transform: uppercase;
  }

.dialogMap{
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000090;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerListDistrict{
    height: 80%;
    overflow: auto;
    margin-top: -22px;
}

.containerListDistrict::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  .containerListDistrict::-webkit-scrollbar-track {
    background: #00853F26;
}
  
  .containerListDistrict::-webkit-scrollbar-thumb {
    background-color: #18CF93;
    border-radius: 0px;
    border: 3px solid #18CF93;
  }

.containerHeaderListDistrict{
    height: 10%;
}

.btnConfirmDistrict{
    width: 100% !important;
}

.contMainBtnDistrict{
    justify-content: center;
}
.custom-dot {
    background: rgb(24, 207, 147);
    width: 100%;
    height: 5px;
    border-width: 0;
    /* margin-top: -20px; */
    /* padding: 0 20px; */
  }
  .react-multi-carousel-dot-list {
    position: absolute;
    top: 95% !important;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    margin: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    margin-bottom: 5% !important;
}
  li {
    width: 100%;
  }
  .custom-dot--active {
    background: rgb(0, 133, 63);
  }

  .react-multi-carousel-item--active
    {
         flex: 1 1 auto;
         position: relative;
         width: 481px !important;
    }
  
/*@media screen and (max-width: 1680px) {
    .containerChiSiamo{
        padding: 20px;
    }
    .boxPeopleAgency{
        align-items: center;
      }
    .containerZone{margin: 10px 0;}
    /*.containerPres{ padding: 40px; }*/   
/*}*/

/*@media screen and (min-width: 993px) and (max-width: 1368px) {
    .margin-top-card{
        margin-top: 0px !important;
    }
}
@media screen and (min-width: 656px) and (max-width: 992px) {
    .margin-top-card{
        margin-top: 20px !important;
    }
}

@media screen and (max-width: 655px) {
    .margin-top-card{
        margin-top: 20px !important;
    } 
}*/
  .map-mobile{
      display: none;
  }

/* mobile */ 

@media all and (max-width: 767px){
    
    .map-desktop{
        display: none!important;
    }
    .map-mobile{
        display: block;
    }
    .btnCloseMap-mobile {
        width: 100%;
        height: 10%;
        position: absolute;
        bottom: 10%;
        right: 0;
        background: rgb(24, 207, 147)!important;
        border-color: rgb(24, 207, 147)!important;
        border-radius: 0!important;
    }
    #mapMilano-mobile>div{
        position: relative!important;
        height: calc(100vh - 160px)!important;
        width: 100vw!important;
    }
    .btn-zone{
        width: 100%;
        height: 60px;
        position: absolute;
        top: 79px;
        left: 0;
        background: url(../../images/icons/seleziona-zone-mobile.svg);
        background-size: cover;
    }
    .dialogMap{
        background-color: transparent;
        height: 100vh!important;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9998;
    }
    
    .col-zona-open {
        position: absolute; 
        transform: translateY(-90%) ;
    }
    .col-zona-closed {
        position: absolute; 
        transform: translateY(10%) ;
    }

    .col-zona {
        background: white;
        height: 100vh;
        width: 100vw;
        display: block;
        animation: all 1s ease-in;
        
    }

    .containerZone{
        margin: 15px 0px;
        padding: 5px;
        height: auto;
        width: 95%;
    }
    .separatorMap-mobile{
        height: 2px;
        width: 100%;
        background-color: #88888854;
        border-radius: 10px;
        margin: 20px 0 30px;
    }
    .tileSelMap-mobile{
        font-weight: bold;
        color: #888888;
        letter-spacing: 0.5px;
        width: 80%;
    }
    .close-zone-mobile{
        width: 100%;
        height: 90px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: url(../../images/icons/btn-conferma2-shadow.svg);
        background-size: cover;
        background-position: bottom;
        z-index: 3;
    }
    .containerHeaderListDistrict-mobile{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
    }
    .close-zone{
        font-size: 30px;
        color: #888888;
    }
    .gradient-zone{
        height: 15%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: linear-gradient(0deg, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%);;
    }

    .boxSelMap-mobile{
        padding: 80px 30px 30px 30px;
    }
    .containerListDistrict-mobile{
        height: 70vh;
        overflow-y: scroll;
    }

    .gradientHome{
        width: 100%;
        margin-top: 0;
        height: 40vh;
        background: transparent linear-gradient(-359deg, #E3F5ED 60%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
        background-size: 100%;
        background-repeat: no-repeat; 
    }

    .containerBgHome{
        height: 40vh;
        background-size: 215% auto;
        background-position: left bottom;
    }

    .searchContainer{
        box-shadow: 2px 2px 10px rgb(0 0 0 / 35%);
    }

    .searchBox{
        transform: translateY(20vh)
    }

    .selectFilterEmpty{
        width: 100%!important;
    }
    .selectFilter{
        height: 35px!important;
    }
    .input-locali{
        margin-left: 8.333%;
    }

    .rowSliderApp{
        position: relative;
        top: 25vh;
        background-color: white;
        flex-flow: column nowrap;
        padding: 0 24px;
    }
    .react-multi-carousel-list{
        align-items: flex-start;
        height: 550px!important;
    }
    .containerPres{
        padding: 0; 
        margin-top: 30px;
    }
    .react-multiple-carousel__arrow{
        display: none;
    }

    .btnSelHome{
        margin-top :-220px;
    }
    .containerlabel.col-lg-12.col-md-12.col-sm-12.col-12 {
        margin-top: -186px;
    }

    .labelSelect{
        margin-bottom:10px;
    }
      
    .titleHome{
        font: normal normal 200 30px/42px 'haboro-contrast-normal' ,sans-serif;
        text-align:left;
        padding-left: 35px;
    }
    .boxTitleHome{
        padding-left:0;
    }
    .subtitleHome{
        font:normal normal 300 16px/23px 'proxima-nova',sans-serif;
        margin-top:0;
        padding: 0px 100px 0 30px;
        letter-spacing: 0.5px;
    }
    .btnSelHome{
        height:35px;
        font-size:12px !important;
    }

    .title-block{
        font:normal normal 300 22px/46px 'haboro-contrast-normal',sans-serif;
        padding: 0!important;
        text-align: center;
        margin-bottom: 0;
        margin-top: 15px;
    }

    .text-block{
        font:normal normal 300 14px/22px 'proxima-nova', sans-serif;
        margin-bottom:30px;
        padding: 15px;
        margin-top: 0;
    }

    .textActivity{
        font:normal normal 300 14px/22px 'proxima-nova', sans-serif;

    }
    .containerGlobalActivity{
        display:block;
    }
    .containerActivityLeft{
        width: 90%;
        padding:10px;
        margin: 0!important;
    }
    .containerBoxTextActivity{
        position: relative;
        top: 30vh;
        padding: 24px;
    }

    .containerListAct{
        width: 100%;
        height: auto;
        padding-right: 55px;
        padding-left: 55px;
        padding-top:50px;
        margin-top: 25vh;
        background-size: cover;

    }
    
    .margin-top-card{
        display: flex;
        flex-flow: column;
    }
    .margin-top-card div{
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-flow: column;
        
    }
    .imageChisiamo{
        height: 50%;
        margin-top:0;
    }

    .text-multiple-card {
    font: normal normal 300 12px/24px 'haboro-contrast-normal', sans-serif;

    }
    .title-multiple-card{
        font:normal normal normal 15px/24px 'haboro-contrast-normal', sans-serif;
        text-align: center;
    }
    .text-multiple-card{
        text-align: center;
    }

    .containerChiSiamo{
        padding:0px;
    }

    .boxPeopleAgency{
        display: block;
        width:90% !important;
        margin-top:20vh!important;
    }

    .searchDesk{
        display: none;
    }
    .searchMobile{
        display: flex;
    }

    .searchHeader{
        display:block;
        box-shadow: rgb(0 0 0 / 16%) 0px 0px 34px;
        margin:0 auto;
        justify-content: center;

    }
    .row{
        --bs-gutter-x:0 !important;
     
    }

    .titleCardApartment{
        font: normal normal 300 14px/19px 'haboro-contrast-normal',sans-serif;
    }
    .boxIconCardAppartment{
        width:35%;
    }

    .card-img-top{
        height: 200px;
    }
    .containerInfoAgency{
        height: auto;
    }
    .elementBkgInfoAgencyBottom{
        height: 180px;
    }
    .genCardComp {
        width: 60%;
        margin-left: -155px;
    }
    .containerListAct .container-fluid .row:last-child{
        margin-top: 0!important;
    }
   
}

@media all and (min-width: 768px) and (max-width: 1023px){
    .map-desktop{
        display: none!important;
    }
    .map-mobile{
        display: block;
    }
    .map-desktop{
        display: none!important;
    }
    .map-mobile{
        display: block;
    }
    .btnCloseMap-mobile {
        width: 100%;
        height: 80px;
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgb(24, 207, 147)!important;
        border-color: rgb(24, 207, 147)!important;
        border-radius: 0!important;
    }
    #mapMilano-mobile>div{
        position: relative!important;
        height: calc(100vh - 160px)!important;
        width: 100vw!important;
    }
    .btn-zone{
        width: 100%;
        height: 120px;
        position: absolute;
        top: 70px;
        left: 0;
        background: url(../../images/icons/seleziona-zone-mobile.svg);
        background-size: 65%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .dialogMap{
        background-color: transparent;
        height: 100vh!important;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    
    .col-zona-open {
        position: absolute; 
        transform: translateY(-91%) ;
    }
    .col-zona-closed {
        position: absolute; 
        transform: translateY(10%) ;
    }

    .col-zona {
        background: white;
        height: 100vh;
        width: 100vw;
        display: block;
        animation: all 1s ease-in;
        
    }

    .containerZone{
        margin: 15px 0px;
        padding: 8px;
        height: auto;
        width: 90%;
    }
    .separatorMap-mobile{
        height: 2px;
        width: 100%;
        background-color: #88888854;
        border-radius: 10px;
        margin: 20px 0 30px;
    }
    .tileSelMap-mobile{
        font-weight: bold;
        color: #888888;
        letter-spacing: 0.5px;
        width: 80%;
    }
    .close-zone-mobile{
        width: 100%;
        height: 105px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: url(../../images/icons/btn-conferma2-shadow.svg);
        background-size: 60%;
        background-position: bottom;
        background-repeat: no-repeat;
        z-index: 3;
    }
    .containerHeaderListDistrict-mobile{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
    }
    .close-zone{
        font-size: 30px;
        color: #888888;
    } 
    .gradient-zone{
        height: 15%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: transparent;
    }
 
    .boxSelMap-mobile{
        padding:80px 50px 50px 50px;
    }
    .containerListDistrict-mobile{
        height: 75vh;
        overflow-y: scroll;
    }
    .colGoogleMap{
        padding: 0!important;
    }




    .containerBody{
        overflow-x : none; 
    }
    .gradientHome{
        height: 60vh;
        background: transparent linear-gradient(359deg, #E3F5ED 60%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    }
    .containerBgHome{
        width: 100%;
        height: 60vh;
        background-position: 20% center;
        background-size: cover;
    }
    .boxTitleHome{
        padding-left: 60px;
    }

    .searchBox{
        transform: translateY(-10vh)
    }

    .titleCardApartment{
        font:normal normal 300 13px/19px 'haboro-contrast-normal',sans-serif;
    }
    
    .title-multiple-card {
        font: normal normal normal 20px/24px 'haboro-contrast-normal', sans-serif;
    }   
    
    .text-multiple-card {

        font: normal normal 300 16px/24px 'haboro-contrast-normal', sans-serif;
    }

    .card-img-top{
        height: 200px;
    }
    .textIconCard{
        font: normal normal 400 12px/14px 'proxima-nova', sans-serif;
    }
    .react-multiple-carousel__arrow{
        display: none;
    }
    .containerPres{
        padding: 0;
    }
    .containerActivityLeft{
        padding: 24px 24px 0 0 ;
    }
    .containerActivityLeft:last-child{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .containerBoxTextActivity{
        padding: 80px 24px;
    }
    .containerListAct{
        padding: 24px;
    }
    .boxPeopleAgency{
        display: flex;
        flex-flow: column;
        transform: translateY(20%);
        padding: 0!important;
    }
    .boxPeopleAgency div{
        width: 100%;
    }
    .boxPeopleAgency div:last-child{
        padding: 24px;
    }
    .containerInfoAgency{
        height: auto;
    }
    .title-block{
        padding: 0;
    }
    .rowSliderApp{
        width: 100vw;
    }
}

@media all and (min-width: 1024px) and (max-width: 1366px){

    .boxTitleHome{
        padding-left: 100px;
    }
    .containerBgHome{
        background-position: 20%;
        background-size: cover;
    }
    .btnSearch{
        width: 100%;
    }
    .labelSearch{
        letter-spacing: 0;
    }
    .containerPres{
        padding: 0;
    }
    
    .containerBoxCarousel, .rowSliderApp, .rowSliderApp .react-multi-carousel-list{
        height: 800px;
    }
    .react-multi-carousel-dot-list{
        transform: translateY(-50px);
    }
    .title-block{
        padding: 40px 0 0 30px;
    }
    
    .textMainBtn{
        margin: 0 auto;
    }
    .containerBoxTextActivity{
        padding: 50px 50px 0 50px;
    }
    .containerListAct{
        padding: 70px;
    }
    .margin-top-card div {
        margin-top: 0px;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center!important;
        height: 180px!important;
    }
    .iconHomeServizi, .iconHomeValutazioniImmobiliari, .iconHomeIndagini{
        height: 50px!important;
        display: block;
    }
    .boxPeopleAgency{
        height: auto;
        width: 90%!important;
    }
    .containerChiSiamo{
        padding: 24px;
    }
    .containerBoxCarousel{
        width: 100vw;
    }
    .rowSliderApp{
        margin: 0!important;
    }

    .card-img-top{
        height: 200px;
    }

    

}


  /*@media only screen and (device-width: 1366px)and (device-height:1024px){

    .card-img-top{
        height: 300px;
    }

    .textIconCard{
        font: normal normal 400 18px/14px 'proxima-nova', sans-serif;
    }

    .titleCardApartment{
        font: normal normal 300 15px/19px 'haboro-contrast-normal', sans-serif;
    }
  }



  @media only screen and (device-width: 844px) and (device-height:390px){
    .containerBgHome{
        height: 500px;
    }
    
    
    .titleHome{
        font: normal normal 200 42px/65px 'haboro-contrast-normal', sans-serif;
    }
    .subtitleHome {
        font: normal normal 300 20px/27px 'proxima-nova', sans-serif;
    }

    .card-img-top{
        height: 150px;
    }

    .boxIconCardAppartment{
        width: 35%;
    }
    

    .title-block {
        font: normal normal 300 30px/56px 'haboro-contrast-normal', sans-serif;
    }

    .text-block {
        font: normal normal 300 14px/20px 'proxima-nova', sans-serif;
    }

    .textActivity{
        font: normal normal 300 14px/20px 'proxima-nova', sans-serif;
    }
    .containerListAct{
        padding:20px;
    }
    .containerChiSiamo{
        padding:0px;
    }

    .elementBkgInfoAgencyTop{
        height: 350px;
    }

    .imageChisiamo{
        width:95%;
        height: 65%;
    }


    .elementBkgInfoAgencyBottom{
        height: 150px;
    }

  }*/