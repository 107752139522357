@media print {
  @page {
    size: A4 portrait;
  }
  .print{
    display: block!important;
  }
  .no-print{
    display: none !important;
  }
  .print-impose-full-width{
    width: 100% !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.print{
  display: none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.CookieConsent {
  width: 450px!important;
  left: 30px!important;
  position: fixed!important;
  bottom: 30px!important;
  background: #f3f3f3!important;
  color: black!important;
  text-align: center;
  border: 1px solid #18CF93;
  font-size: 13px!important;
  padding: 15px 20px;
  
}
.CookieConsent div:nth-child(2){
  width: 100%!important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  flex-flow: row-reverse!important;
}
#rcc-decline-button, #rcc-confirm-button{
  background-color: #18cf921c!important;
  border: 1px solid #18cf92!important;
  color: #17c088!important;
  padding: 6px 30px!important;
}
#rcc-decline-button:hover, #rcc-confirm-button:hover{
  background-color: #18cf92!important;
  color: white!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-device-width:767px){

  .CookieConsent{
    width: 90vw!important;
    padding: 0;
    left: 20px!important;
  }
  .CookieConsent div:nth-child(1){
    margin: 0!important;
    padding: 15px;
  }
  #rcc-decline-button, #rcc-confirm-button{
    padding: 10px!important;
  }
}
