@import url("https://use.typekit.net/wrb8jux.css%22%3E");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
}

a:hover{
  color: #18CF93 !important;
}

.mainBtn{
  background: #18CF93 !important;
  border-radius: 0px !important;
  border: none !important;
  color: #ffffff !important;
  font-size: 12px !important;
  border-left: 2px solid #4A9461 !important;
  padding: 0px !important;
  height: 35px !important;
  background: linear-gradient(to right, #18CF93 50%, #5DDBB2 50%) !important;
  background-size: 200% !important;
  background-position:left bottom !important;
  transition: .5s ease-out !important;
}

.mainBtn:hover{
  background-position:right bottom !important;
}

.mainBtn:focus{
  box-shadow: none !important;
}

.contMinBtn{
  width: 100%;
  display: flex;
  align-items: center;
}

.iconMainButton{
  padding: 12px;
}

