@import url("https://use.typekit.net/wrb8jux.css");

.containerContact{
    padding: 100px;
    position: relative;
}

.dimenSections{
    padding: 50px;
}

.containerTitle{
    width: 100%;
    line-height: normal;
}

.mainTitle{
    font-family: 'haboro-contrast-normal', sans-serif;
    color: #5B5B5B;
    text-align: left;
    font-size: 44px;
    letter-spacing: 0px;
    color: #5B5B5B;
}


.subtitle{
    font-family: 'haboro-contrast-normal', sans-serif;
    color: #5B5B5B;
    text-align: left;
    font-size: 44px;
    letter-spacing: 0px;
    color: #5B5B5B;
    font-weight: 300;
}

.subtitleContact{
    font-size: 16px;
    margin-top: 10px;
    color: #00792D;
    opacity: 0.86;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 300;
}

.boxAddress{
    width: 70%;
    padding: 0 10px;
    margin-top: 15%;
    background: #F5F9F7 0% 0% no-repeat padding-box;
    border: 1px solid #00853F40;
    border-radius: 2px;
    opacity: 1;
}

.rowBoxAddress{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #00853F40;
}

.rowBoxAddress:last-child{
    border-bottom: none;
}

.iconBoxAddress{
    transform: rotateY(180deg);
    width: 10%;
    text-align: center;
    font-size: 20px;
}

.textBoxAddress{
    width: 90%;
    text-align: left;
    font-size: 14px;
    font-family: 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
    color: #3D3A3C;
}

.titleLow{
    color: #5B5B5B;
    font-size: 13px;
    text-transform: uppercase;
    margin:10px 0px;
    font: normal normal 600 13px/24px 'proxima-nova', sans-serif;
    letter-spacing: 1.79px;
}

.subtitleLow, .subtitleLowTitle{
    color: #5B5B5B;
    font-size: 14px;
    font-style: normal;
    font: normal normal 100 14px/24px 'proxima-nova', sans-serif;
    letter-spacing: 0.39px;
    color: #5B5B5B;
    margin: 0;
}

.subtitleLow:hover{
    color: #18CF93 !important;
}

.containerForm{
    background-color: #F6F6F6;
    border-radius: 8px;
    height: 100%;
    padding: 100px;
}

.labelForm{
    color: #5E5E5E;
    margin: 0pc;
    text-transform: uppercase;
    font-size: 11px;
    margin-left: 20px;
    text-align: left;
    font: normal normal 600 11px/11px 'proxima-nova', sans-serif;
    letter-spacing: 2.31px;
    color: #5E5E5E;
    text-transform: uppercase;
    opacity: 1;
}

.inputForm{
    background-color: #F6F6F6;
    letter-spacing: 0px;
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0px;
    color: #B7B7B7;
    text-align: left;
    font: normal normal 300 18px/27px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    opacity: 1;
}

.inputForm::placeholder{
    color: #B7B7B7 !important;
}

.containerInputForm{
    margin-bottom: 20px;
}

.btnForm{
    margin-top: 20px;
}

.secondTitleForm{
    font-size: 11px;
    margin: 20px 0px 10px 0px;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #808080;
    text-align: left;
    font: normal normal medium 11px/12px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #808080;
    opacity: 1;
}

.checkForm {
    font-size: 10px;
    color: #a3a3a3;
}

.labelCheckbox{
    color: #808080;
    text-align: left;
    font: normal normal 300 11px/12px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #808080;
    opacity: 1;
}

.containerFooterContact{
    padding: 20px;
}

.footerSx{
    background-color: #cccccc;
}
.dimenSections{
    padding: 50px;
}
.dxdimenSections{
    padding: 50px;
    display:flex;
}
.dxdimenSections .row{
    padding: 100px;
    margin-top: 150px;
}
.footerDx{
    background-color: #cccccc;
}

.colFooterSx{
    padding: 0px;
    padding-left:50px;
}
.colFooterDx{
    padding: 0px;
    padding-right: 50px;
}

.boxFooterDx{
    justify-content: right;
    cursor: pointer;
}

.boxFooterGen{
    width: 100%;
    display: flex;
    border-top: 1px solid #cccccc;
    padding: 20px 0;;
}

.footerContact{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 100px;
}

.simpletext:hover{
    color: #808080 !important;
}

.form-check-input{
    cursor: pointer;
    border-radius: 0px !important;
}
.form-check-input:checked {
    background-color: #5DDBB2;
    border-color: #5DDBB2;
    border-radius: 0px;
}
.form-check-input:focus{
    box-shadow: none !important;
    border: 1px solid rgba(0,0,0,.25);
}

.form-control:focus{
    background-color: #f6f6f6;
    border: none;
    border-bottom: 1px solid #ced4da;
    box-shadow: none;
}
.link-color{
    color: #5b5b5b;
}

@media  screen and (max-width: 1680px) {
    .dimenSections{ padding: 0px; }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px){
    .containerBody{
        overflow-x : none; 
    }
    .dxdimenSections{
        padding: 30px;
    }

    .boxAddress {
        width: 95% !important;
        margin-top: 71px !important;
    }	
    .containerContact{	
        padding:50px !important;	
    }	
    .boxContactAgency.row {	
        margin-top: -207px !important;	
        margin-left: 390px !important;	
        padding:0px !important;	
    }	
    .containerForm{	
        margin-top:45px !important;	
        padding:30px !important;	
    }	
    .boxContact.row{	
        display: block;	
    }	
    
    .containerTitle {	
        width: 80%;	
    }	
    	
    .mainTitle{	
        font-size:35px;	
    }	
    .subtitle {	
        font-size: 30px;	
    }	
    .colFooterSx {	
        padding-left:30px !important;	
    }	
    .boxFooterGen{	
        margin-left:12px;	
    }	
    .subtitleLow, .subtitleLowTitle{	
        font: normal normal 100 11px/24px 'proxima-nova', sans-serif;	
    }	


}


@media only screen and (min-device-width: 1024px) and (max-device-width: 1365px){	
    .containerContact {	
        padding: 80px	
    }	
    .mainTitle {	
        font-size:35px;	
    }	
    .subtitle{	
        font-size:30px;	
    }	
    .containerForm{	
        padding:25px;	
    }	
    .subtitleContact {	
        font-size: 18px;	
    }	
}	



@media only screen and (max-device-width:767px){
    .dimenSections{
        padding:0px;
        margin-bottom:20px;
        margin-top:20px;
    }
    .mainTitle{
        margin-top:20px;
    }
    .mainTitle,.subtitle{
        font-size:22px;
    }
    .subtitleContact{
        font-size:15px;
    }
    .boxAddress{
        width:100%
    }
    .containerContact{
        margin-top: -60px;
        padding:50px;
    }
    .containerForm{
        padding:20px;
    }

    .inputForm{
    font:normal normal 300 13px/20px 'haboro-contrast-normal',sans-serif;
    }
    .subtitleLow, .subtitleLowTitle{
        font: normal normal 100 10px/20px 'proxima-nova',sans-serif;
    }
    .footerContact{
        padding: 0px;
    }
    .boxFooterGen{
        border-top: none;
        padding:0px 0px;
    }
    .boxFooterDx{
        justify-content: center;
    }
    .dxdimenSections{
        padding: 0;
    }
    .dxdimenSections .row {
        margin-top: 20px;
    }
}


@media only screen and (device-width:844px) and (device-height:390px){
    .containerContact{
        padding:50px;
    }

    .mainTitle{
        font-size:28px;
    }

    .subtitle{
        font-size:22px;
    }

    .dimenSections{
        padding:30px;
    }


    .containerForm{
        padding:18px;
        width:120%;
    }
    .boxAddress{
        width: 90%;
        padding: 0 10px;

    }

    .subtitleLow, .subtitleLowTitle{
        font: normal normal 100 12px/24px 'proxima-nova', sans-serif;
    }
    .boxFooterGen{
        padding: 10px 0;
    } 
}