.containerHeaderPage{
    width: 100%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border: 1px solid #8B8B8B66;
    opacity: 1;
    padding: 100px 200px;
}

.titleHeader{
    text-align: left;
    font: normal normal 200 48px/56px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    margin-bottom: 30px;
}

.mainContainer{
    width: 100%;
    background: #F3F3F3 0% 0% no-repeat padding-box;
}

.containerBodyPage{
    width: 90vw;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #8B8B8B66;
    opacity: 1;
    margin: 0 auto;
}

.headerCardList{
    width: 100%;
    display: flex;
    background: #F3F3F3 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 30px;
}

.bodyCardList{
    width: 100%;
    padding: 70px 70px 0px 70px;
}

.boxCardApartment{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.styleCardApartment{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 36px #00000029;
    opacity: 1;
    width: 80%;
}

.footerCardList{
    width: 100%;
}

.containerInfoApartment{
    width: 100%;
    padding: 100px;
    background: #F3F3F3 0% 0% no-repeat padding-box;
}



.boxInfoAp{
    width: 100%;
}

.iconInfoAp{
    background: url('../../images/icons/pittogramma.svg');
    background-repeat: no-repeat;
    /* background-size: 121px 98px; */
    height: 40px;
    width: 100%;
    /* background-position: center center; */
}

.titleInfoAp{
    text-align: left;
    font: normal normal 300 36px/40px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
}

.textInfoAp{
    font: normal normal 300 22px/27px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
}

.selectFilterEmpty{
    width: 100% !important;
    background: #F1F1F1 0% 0% no-repeat padding-box !important;
    /* border: 1px solid #8B8B8B; */
    border-radius: 0px !important;
    border: 1px solid #8B8B8B !important;
    height: 45px ;
    font: normal normal 300 18px/24px 'haboro-contrast-normal', sans-serif !important;
    letter-spacing: 0px !important;
    color: #8B8B8B !important;
}

.selectFilter{
    width: 100% !important;
    background: #E3F5ED 0% 0% no-repeat padding-box !important;
    border: 1px solid #00853F40 !important;
    border-bottom: 2px solid #00853F !important;
    border-radius: 0px !important;
    height: 45px;
    font: normal normal 300 18px/24px 'haboro-contrast-normal', sans-serif !important;
    letter-spacing: 0px !important;
    color: #00853F !important;
}

.selectFilterEmpty:hover{
    outline: none !important;
}

.selectFilterEmpty:focus-visible{
    outline: none !important;
}

.itemSelect{
    font: normal normal 300 14px/18px 'haboro-contrast-normal', sans-serif !important;
    letter-spacing: 0px !important;
    color: #00853F !important;
}

.itemSelect:hover{
    background: #E3F5ED 0% 0% no-repeat padding-box  !important;
    opacity: 0.79 !important;
}

.MuiOutlinedInput-notchedOutline{
    /* border-color: red !important; */
    border: none !important;
}

.iconBtnAddList{
    background: url('../../images/icons/label-altri-risultati.svg');
    background-repeat: no-repeat;
    background-size: 320px 60px;
    height: 60px;
    width: 320px;
    margin: 0 auto;
    cursor: pointer;
}

.iconBtnProced{
    background: url('../../images/icons/icona-procedi.svg') #5DDBB2;
    background-repeat: no-repeat;
    background-position: center center;
    height: 35px;
    width: 35px;
}

.rowFilterCardList{
    width: 100%;
    display: flex;
}

.text-blockricerca {
    margin-top: 30px;
    margin-bottom: 55px;
    font-size: 25px!important;
    text-align: center;
    font: normal normal 300 16px/22px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
}

.title-blockricerca{
    margin-bottom: 80px;
    text-align: center;
    font: normal normal 300 48px/56px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;

}
.containerPress-view{
    padding: 0 0 80px 0;
}

.text-blockricercah2{

    margin-top:30px;
    text-align: center;
    /* font: normal normal 300 16px/22px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86; */

}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 4px solid #4ed649; /* Light grey */
    border-top: 6px solid #fdfdfd; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

.spinner-container {
    align-items: center;
    display: flex;
    height: 350px;
    justify-content: center;
    flex-flow: column;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {

    .containerInfoApartment .mainBtn {
        width: 100%;
    }
    .containerHeaderPage{
        padding: 100px;
    }
    .text-blockricerca{
        font-size: 21px!important;
        margin-bottom: 20px!important;
    }
    .title-blockricerca{
        margin-bottom: 0;
        padding-top: 20px;
    }
}




  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .containerBody{
        overflow-x : none;
    }
    .containerInfoApartment{
        padding:50px
    }
    .iconRequestInfo{
        height: 200px;
    }

    .titleInfoAp{
        font: normal normal 300 25px/30px 'haboro-contrast-normal', sans-serif;
    }

    .textInfoAp{
        font: normal normal 300 18px/25px 'proxima-nova', sans-serif;
        margin-left:20px;
        margin-top:20px;

    }
    .containerHeaderPage{
        padding: 50px 50px;
    }
    .textMainBtn{
        padding:0px 20px;
    }
    .styleCardApartment{
        width: 100%;
    }
    .boxIconCardAppartment{
        padding: 5px;
    }
    .card{
        height: 400px;
    }
    .bodyCardList{
       padding: 30px 50px 0 50px;
    }
    .containerPres-view{
        padding: 0 30px 50px 30px;
    }
    .text-blockricerca{
        font-size:20px!important;
    }
    .title-blockricerca{
        font-size: 35px;
        margin-bottom: 0px;
    }

}

@media only screen and (max-device-width: 767px){
    .containerHeaderPage{
        padding:40px;
    }


    .bodyCardList{
        padding:0px;
        margin-top:35px;
    }

    .iconBtnAddList{
        background-size: 300px 60px;
    }
    .containerInfoApartment{
        padding:20px;
        height: 250px;
        display: flex;
        align-items: center;
    }
    .containerInfoApartment .row div div{
        display:flex;
        flex-flow:column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height:100%;
    }
    .titleInfoAp-strong {
        text-align:center;
    }
    .iconInfoAp {
        width: 50px;
    }
    .titleInfoAp{
        font:normal normal 300 18px/25px 'haboro-contrast-normal', sans-serif;
    }
    .textInfoAp{
        font:normal normal 300 15px/20px 'proxima-nova', sans-serif;
        margin-top:0;
        margin-left:0;
        text-align: center;
    }


    button.viewApp.mainBtn.btn.btn-primary{
        margin-top:35px !important;
        margin-left:-119px;
    }
    .col-12{
        flex:auto !important;
    }
    .textMainBtn{
        padding: 0px 20px !important;
    }

    .iconProced{
        margin-top:20px;
    }

    .boxCardApartment.col-lg-4.col-md-4.col-sm-4.col-4{
        width: auto !important;
    }

    .selectFilterEmpty{
        width: 100%!important;
        height: 35px !important;

    }
    .text-blockricerca, .title-blockricerca{
        padding: 0 15px;
    }
    .text-blockricerca{
        font-size: 17px!important;
        margin-bottom: 30px;
    }
    .title-blockricerca {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .containerPress-view {
        padidng: 25px;
    }
}
