.containerHeader{
    padding: 100px 610px;
    background-color: #F5F5F5;
    text-align: center;
}

.containerBody{
    padding: 50px;
    background-color: #ffffff;
    text-align: center;
}

.titleHaed{
    color: #3D3A3C;
    text-align: center;
    font: normal normal normal 48px/56px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    font-weight: 300;
}

.subtitleHead{
    margin-top: 20px;
    display: grid;
    text-align: center;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
}

.tabBody{
    width: 70%;
    height: 308px;
    margin: auto;
    border: 1px solid #cccccc;
    display: flex;
    margin-top: 40px;
}

.tabBodyLast{
    width: 70%;
    height: 308px;
    margin: auto;
    display: flex;
    margin-top: 40px;
}

.boxBodyIcon{
    width: 20%;
    background-color: #F5F9F7;
}

.bodyIconServizi{
    background: url('../../images/icons/icona-servizi-intermediazione.svg');
    background-repeat: no-repeat;
    background-size: 121px 98px;
    height: 100%;
    width: 100%;
    background-position: center center;
}

.bodyIconIndagini{
    background: url('../../images/icons/icona-indagini.svg');
    background-repeat: no-repeat;
    background-size: 121px 98px;
    height: 100%;
    width: 100%;
    background-position: center center;
}

.bodyIconValutazioni{
    background: url('../../images/icons/icona-valutazioni.svg');
    background-repeat: no-repeat;
    background-size: 121px 98px;
    height: 100%;
    width: 100%;
    background-position: center center;
}

.bodyIconAssistenza{
    background: url('../../images/icons/icona-assistenza.svg');
    background-repeat: no-repeat;
    background-size: 121px 98px;
    height: 100%;
    width: 100%;
    background-position: center center;
}

.bodyIconHomeStaging{
    background: url('../../images/icons/icona-home-staging.svg');
    background-repeat: no-repeat;
    background-size: 121px 98px;
    height: 100%;
    width: 100%;
    background-position: center center;
}

.boxBodyFirstText{
    width: 40%;
    text-align: left;
    height: 80%;
    margin: auto;
    border-right: 1px solid #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
}
.boxBodySecondText{
    width: 40%;
    text-align: left;
    height: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 108px;
}

.containerFirstText{
    color: #000000;
    padding: 150px;
}

.numberFirstText{
    font: normal normal 600 14px/17px 'proxima-nova', sans-serif;
    letter-spacing: 1.96px;
    color: #00853F;
    text-transform: uppercase;
    opacity: 1;
}

.titleFirstText{
    text-align: left;
    font: normal normal normal 25px/24px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
}

.subtitleFirstText{
    font: normal normal normal 18px/24px 'haboro-contrast-normal', sans-serif;
    font-style: italic;
    letter-spacing: 0px;
    color: #3D3A3C;
}

.subtitleSecondText{
    text-align: left;
    font: normal normal 300 14px/20px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.9;
}

.lastTab{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
}

.lastTabtext{
    text-align: left;
    font: normal normal medium 14px/20px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
}

@media  screen and (max-width: 1680px) {
    .containerHeader{
        padding: 100px 400px;
    }
    .boxBodySecondText{
        padding: 50px;
    }
    .containerFirstText{
        padding: 100px;
    }
}

@media screen and (min-width: 656px) and (max-width: 992px) {

}

@media screen and (max-width: 655px) {

}

/* superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  } */


  @media only screen and (min-device-width: 768px) and (max-device-width: 1023px){
    .containerBody{
        overflow-x : none; 
    }
    .text-footer {
        font:normal normal 100 11.5px/24px 'proxima-nova',sans-serif;
    }

    .boxBodySecondText{
        padding:20px;
    }

    .containerHeader{
        padding: 30px 100px;
        margin-top:30px;
    }

    .bodyIconServizi,.bodyIconIndagini, .bodyIconValutazioni,.bodyIconAssistenza,.bodyIconHomeStaging{
        background-size: 70px 70px;

    }

    .lastTab{	
        padding:30px;	
    }
    .lastTabtext{
        font-size:13px;
    }

    .tabBodyLast {	
        height: 138px !important;	
    }	
    .tabBody {	
        width: 80% !important;	
    }	
    .tabBodyLast {	
        height: 180px !important;	
        width:89% !important;	
    }	
    .subtitleSecondText {	
        font: normal normal 300 13px/20px 'proxima-nova', sans-serif;	
    }
}


@media only screen and (min-device-width: 1024px) and (max-device-width: 1365px){		
    .containerHeader {		
        padding: 50px 200px;		
        margin-top: 30px;		
    }		
    .tabBody {		
        width: 80% !important;		
    }		
    .subtitleSecondText {		
        		
        font: normal normal 300 15px/20px 'proxima-nova', sans-serif;		
    }		
    .lastTab{		
        padding:30px;		
    }		
    .tabBodyLast{		
        height: 230px;		
    }		
}


@media only screen and (max-device-width:767px){
    .titleHaed{
        font: normal normal normal 26px 'haboro-contrast-normal', sans-serif;
    }
    .mainLogo{
        justify-content: center;
    }
    .containerHeader{
    margin-top: 25px;
    padding:30px;
    }
    
    .containerBody{
        padding: 0px;
    }
    .tabBody{
        width: 100%;
    }
    .boxBodySecondText{
        padding: 0px;
    }
    .bodyIconServizi,.bodyIconIndagini,.bodyIconValutazioni,.bodyIconAssistenza,.bodyIconHomeStaging{
        background-size:50px 44px;
    }
    .boxBodyIcon{
        width:15%;
    }
    .titleFirstText{
        font:normal normal normal 16px/18px 'haboro-contrast-normal',sans-serif;
    }
    .subtitleFirstText{
        font:normal normal normal 11px/16px 'haboro-contrast-normal',sans-serif;
    }
    .subtitleSecondText{
        font:normal normal 300 12px/15px 'proxima-nova',sans-serif;
    }
    .lastTab{
        width:100%;
        padding: 0px;
        
    }

    .rowNavbar{
        padding: 10px;
    }
    .tabBodyLast{
        height: 220px;
        width:100%;
        display:block;
    }
    .lastTabtext{
        font-size:12px;
        padding:0px;
        margin-bottom:20px;
    }
    

    .title-footer{
        font:normal normal 600 12px/15px 'proxima-nova',sans-serif;
        margin-top:15px;
    }
    .text-footer{
        font:normal normal 100 12px/18px 'proxima-nova',sans-serif;
    }

    .subFooter{	
        font-size:13px;	
    }

    
    .textLinkFooter{
        font:normal normal 300 10px/14px 'proxima-nova', sans-serif;
        text-align: center !important;
        margin-top:0px;
    }
    .textBtnFooter{
        padding:3px 5px;
    }
    p{
        margin-bottom:7px !important;
    }
    
    .navbar-light .navbar-toggler{
        border-color:transparent;
    }
    
    .itemMenu{
        color: #ffffff !important;
        font-size:15px;
        font: var(--unnamed-font-style-normal) normal medium var(--unnamed-font-size-22)/45px var(--unnamed-font-family-haboro-contrast-norm);
        text-transform: capitalize;
    }
    .itemMenuSel{
        border-color:#ffffff;
    }
    .navbar-nav .nav-link {
        padding-left:3px;
    }
}

@media only screen and (device-width:844px) and (device-height:390px){
 
    .containerBody{
        padding: 0px;
    }
    .containerHeader{
        padding:50px 50px;
    }
    .titleHaed{
        font: normal normal normal 35px/56px 'haboro-contrast-normal', sans-serif;
    }

    .subtitleHead{
        font-size:13px;
    }

    .bodyIconServizi,.bodyIconIndagini,.bodyIconValutazioni,.bodyIconAssistenza,.bodyIconHomeStaging{
        background-size: 50px 70px;
    }

    .titleFirstText{
        font: normal normal normal 23px/24px 'haboro-contrast-normal', sans-serif;
    }

    .subtitleFirstText{
        font: normal normal normal 16px/24px 'haboro-contrast-normal', sans-serif;
    
    }

    .boxBodySecondText{
        padding:10px;
    }

    .subtitleSecondText{
        font: normal normal 300 13px/20px 'proxima-nova', sans-serif;
    }

    .tabBodyLast {	
        height: 195px !important;	
    }


    .lastTabtext{
        font-size:12px;
    }

    .text-footer{
        font: normal normal 100 9.5px/24px 'proxima-nova',sans-serif;
    }

    .containerFooter{
        padding:70px;
        height: 500px;
    }
}