.navbar-collapse{
    justify-content: center;
    align-items: center;
}
.navBarMobile {
    order: 2;
    color: #00853f  ;
    font-size: 26px;
}
.mainLogo {
    order: 1;
}
.contactMob {
    order: 3;
}


.nav-link.active{
    border-bottom: 2px solid #00853F;
}

.itemMenu{
    color: #3D3A3C !important;
    font-size: 12px;
    text-align: center;
    width: 25%;
    text-transform: uppercase;
}

.itemMenuSel{
    border-bottom: 2px solid #00853F;
}

.mainLogo{
    display:flex;
    align-items: center;
    justify-content: right;
}

.styleNavbar{
    width: 100%;
}

.containerNavbar{
    width: 70%;
}
.textMainBtn{
    color: #ffffff;
    padding: 3px 20px;
    text-align: center;
    font: normal normal medium 13px/16px 'proxima-nova', sans-serif;
    letter-spacing: 2.6px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}
.rowNavbar{
    padding: 20px;
    height: 72px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.iconbtnMail{
    background: url('../../images/icons/icona-mail.svg') #5DDBB2;
    background-repeat: no-repeat;
    background-position: center center;
    height: 35px;
    width: 35px;
}
.iconMailMobile{	
    background: url('../../images/icons/iconaMobile-mail.svg');	
    background-repeat: no-repeat;	
    background-position: center center;	
    height: 30px;	
    width: 30px;	
   	
}
.mobileMenu{
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    border-radius: 0 20px 20px 0;
    height: 100%;
    background: #3D3A3C;
    z-index: 999;
    padding: 40px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    animation: all .35s ease-in;
}
.cross{
    font-size: 30px;
    color: #fff;
}
.logoNav {
    max-width: 125px;
    height: auto;
}
.navList{
    height: 35%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    padding: 0;
    margin-top: -80px;
}
.navList li{
    list-style: none;
}
.navList li a{
    text-decoration: none;
    color: #ffffffbe;
}
.nav-contatti{
    padding: 0 10px;
}
.nav-contatti p, .nav-contatti p a {
    color: #ffffff73;
    font-size: 15px;
}
.nav-contatti p:first-child{
    color: #ffffffbe;
    letter-spacing: 0.5px;
}

.closeAnimation{
    transform: translateX(-300px);
}
.showAnimation{
    transform: translateX(0);
}
.iconMailMobile,.icon-bars{
    display: none;
}
.styleNavbar{
    display: flex;
    justify-content: center!important;
}
.item-mobile-active{
    text-decoration: underline!important; 
    text-underline-offset: 8px;
          
}

/* MOBILE  */
@media only screen and (max-device-width:767px){	
    .rowNavbar{
        z-index: 9999;
    }
    .iconMailMobile,.icon-bars{
        display: block;
    }
    .mobileMenu{
        width: 100%;
        border-radius: 0;
    }
    .iconMailMobile{	
        position:absolute;	
        top:30px;
        right: 30px;
    }	
    .iconMailDesk{	
        display:none;	
    }	
    .rowNavbar {	
        height: 86px;	
    }	
    
    .mainLogo{
        justify-content: center;
    }

    .responsive-navbar-nav.navbar-collapse.collapse{
        height: 100vh;
        background:#3D3A3C;
        z-index: 999;
        transform: translateX(100%);
        animation: all ease-in 0.5s;
    }
    .responsive-navbar-nav.navbar-collapse.collapse.show{
        transform: translateX(0);
    }
    .navBarMobile {
        order: 1;
        
        font-size: 20px;
        padding-right: 45px;
    }
    .mainLogo {
        order: 2;
    }
    .contactMob {
        order: 3;
        width: 30%;
    }
    .styleNavbar{
        display: none!important;
    }
    
}	

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px){

    .rowNavbar{
        z-index: 9999;
    }
    .iconMailMobile,.icon-bars{
        display: block;
    }
    .iconMailDesk{
        display: none;
    }
    .navBarMobile {
        order: 1;
    }
    .mainLogo {
        order: 2;
        display: flex;
        justify-content: center;
    }
    .contactMob {
        order: 3;
    }
    .rowNavbar {
        display: flex;
        justify-content: space-between;
    }

    .iconMailMobile{
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 30px;
    }
    .styleNavbar{
        display: none!important;
    }
    



}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px){

    .mainBtn {
        width: 80%;
        padding-top: 50%; 
    }
    .icon-bars,.iconMailMobile{
        display: none;
    }
    .styleNavbar{
        display: flex;
        align-items: center;
        justify-content: center!important;
    }
    
    
}

