/* a:hover{
    color: #18CF93 !important;
} */

.btnFooter{
    background-color: #E8E8E8 !important;
    border-radius: 0px !important;
    border-color: #707070 !important;
    color: #929292 !important;
    font-size: 12px !important;
    border-left: 3px solid #707070 !important;
    padding: 0px !important;
    height: 35px !important;
    transition: .5s ease-out !important;
  }

  .btnFooter:focus{
      box-shadow: none !important;
  }

  .btnFooter:hover,.btnFooter:hover .iconbtnFooter{
    background-color: #ffffff !important;
}
  
  .textBtnFooter{
    padding: 3px 20px;
    text-align: center;
    font: normal normal medium 13px/16px 'proxima-nova', sans-serif;
    letter-spacing: 2.6px;
    color: #929292;
    text-transform: uppercase;
    opacity: 1;
}

.subFooter{
    text-align: left;
    font: normal normal medium 13px/16px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #5B5B5B;
    opacity: 0.9;
}

.iconbtnFooter{
    background: url('../../images/icons/icona-freccia-link-esterno.svg') #E8E8E8;
    background-repeat: no-repeat;
    background-position: center center;
    transition: .5s ease-out !important;
}

.title-footer{
    font: normal normal 600 13px/24px  'proxima-nova', sans-serif;
    letter-spacing: 1.79px;
    color: #5B5B5B;
    text-transform: uppercase;
    opacity: 1;
}

.text-footer{
    text-align: left;
    font: normal normal 100 14px/24px 'proxima-nova', sans-serif;
    letter-spacing: 0.39px;
    color: #5B5B5B;
    opacity: 1;
    margin: 0;
}

.containerFooter{
    position: relative;
    width: 100%;
    height: auto;
    background-color: #E8E8E8;
    opacity: 1;
    padding: 100px;
}

.textLinkFooter{
    text-align: left;
    font: normal normal 300 14px/17px 'proxima-nova', sans-serif;
    letter-spacing: 0.45px;
    color: #5B5B5B;
    margin-top: 20px;
}
.containerFooterLink{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 100px;
}

/*.container-fluid {
    gap: 0px;
}*/

.footer-section-container{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 70px !important
}

/* .footer-section-container .col-lg-12 img{
    margin-left: 130px;
} */
.text-footer-site:hover{
    color: #18CF93 !important;
}

.boxInfoFooter{
    display: grid;
    line-height: 0px;
}

.logoContainer{
    margin-left: 0 !important;
}

@media  screen and (max-width: 1680px) {
    .text-footer{
        font: normal normal 100 10px/24px 'proxima-nova', sans-serif;
    }
    /* .footer-section-container .col-lg-12 img{
        margin-left: 170px;
    } */
}

@media screen and (min-width: 1024px) and (max-width: 1368px) {
    /* .footer-section-container .col-lg-12 img{
        margin-left: 130px;
    } */
}

@media screen and (min-width: 656px) and (max-width: 1023px) {
    
}

@media screen and (max-width: 655px) {
    
}

@media only screen and (max-device-width:767px){
   
    .containerFooter{
        height: 100%;
        padding:24px 24px 0 24px;
    }
    
    .footerContact:before {
        content: "";
        width: 100%;
        height: 1px;
        background: rgba(0,0,0,.3);
        margin: 15px auto 0;
    }
    
    .title-footer{
        font:normal normal 600 12px/15px 'proxima-nova',sans-serif;
        margin-top:15px;
    }
    .text-footer{
        font:normal normal 100 12px/18px 'proxima-nova',sans-serif;
    }
    .subFooter{
        font-size:13px;
        margin-bottom: 30px!important;
    }
    .textLinkFooter{
        font:normal normal 300 10px/14px 'proxima-nova', sans-serif;
        text-align: center !important;
        margin-top:0px;
    }
    .textBtnFooter{
        padding:3px 5px;
    }
    .footerContact{
        position: relative!important;
    }
    .containerFooterLink{
        position: relative;
        padding: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }
    .btnFooter{
        padding: 0 20px!important;
    }
    .boxInfoFooter{
        margin-top: 30px!important;
    }
    .footerContact div{
        padding-right: 0!important;
        padding-left: 0!important;
        text-align: center;
    }
    .boxFooterGen{
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }
    .subtitleLowTitle{
        display: inline;
    }
    .colFooterDx{
        position: relative;
        bottom:15px;
    }
    .textBtnFooter{
        padding:3px 5px;
    }
       
    
}

@media only screen and (min-device-width:768px) and (max-device-width:1023px){ 

    .containerFooter{
        padding: 55px;
        height: auto;
    }
    .footer-section-container div:nth-child(1), .footer-section-container div:nth-child(2){
        width: 50%;
    }
    .contMinBtn{
        width: 100%!important;
    }
    .btnFooter{
        width: 65%;
    }
    .boxInfoFooter:nth-child(3),.boxInfoFooter:nth-child(4),.boxInfoFooter:nth-child(5){
        margin-top: 30px;
        padding-left: 24px;
        margin-left: 73px;
    }
    .boxFooterDx{
        display: flex;
    }
    .colFooterSx{
        padding-left: 0!important;
        width: 66%!important;
    }
    .colFooterDx{
        padding-right: 0!important;
        width: 34%!important;
    }
    .containerFooterLink{
        position: relative;
        padding: 0;
        margin-top: 40px;
    }
    .boxFooterGen{
        display: flex;
    }
    .textBtnFooter {
        padding: 0 5px;
        width: 145px!important;
    }
    .iconMainButton {
        width: 30px!important;
    }
    .footer-section-container{
        margin-top: 45px!important;
    }
    
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1365px){

    .containerFooter{
        padding: 55px 55px 100px 55px;
        height: auto;
    }
}