@import "~react-image-gallery/styles/css/image-gallery.css";



.icon-print-page {
  cursor: pointer;
  border-color: #13BF81;
  background-color: #18CF93;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: 60px;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-print-page img{
    height: auto;
    width: 30px;
}

.icon-share-page{
  background: url('../../images/icons/ic-condividi.svg');
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
  cursor: pointer;
  border-color: #13BF81;
  background-color: #18CF93;
  margin-left: 60px;
  margin-right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.container-list-caratteristiche{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #a1a1a187;
    margin-bottom: 20px;
}

.label-caratteristiche-prima-colonna{
    font: normal normal 500 14px/14px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
    width: 50%;
}

.label-caratteristiche-seconda-colonna{
    font-size: 12px;
    color: #6c6b6c;
    width: 180px;
    font-weight: bold;
}

.text-caratteristiche{
    font: normal normal 300 14px/14px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
    width: 50%;
}

.image-gallery-content .image-gallery-slide .image-gallery-image{
    max-height: calc(100vh - 220px);
}

.thumbnails-swipe-horizontal{
    width: 600px !important;
    left: calc(50% - 300px) !important;
}

.image-gallery-thumbnail-image{
    height: 50px !important;
    width: auto !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{
    width: auto !important;
    height: auto !important;
}

.thumbnails-swipe-horizontal{
    margin-top: 20px;
}

.image-gallery-svg{
    height: 40px !important;
    width: 40px !important;
    color: #00853f !important;
    background-color: #e3f5ed !important;
    border: 1px solid #aad9c2;
}

.btn-close-modal{
    background-color: #909391 !important;
    color: #ffffff !important;
    border: 0px !important;
    border-radius: 0px !important;
    margin-right: 5px;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-header{
    display: flex !important;
    justify-content: flex-end !important;
}

.image-gallery-index{
    background-color: transparent !important;
}

.boxIconDet{
    width: auto !important;
    padding: 10px !important;
}

.buttonGoBack{
    text-decoration: none;
    display: flex;
    align-items:center;
    justify-content:flex-start;
    font: normal normal 600 15px/18px 'proxima-nova', sans-serif;
    letter-spacing: 2.1px;
    color: #00853F;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 30px;
    width: max-content;
}

.buttonGoBack:hover{
    color: #5DDBB2;
}

div a {
    text-decoration: none;
}

.breadcrumbDetail{
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 10px;
    color: #00853f
}

.stylePlayer{
    width: 100%;
    height: 95%;
}

.btnDetailApp{
    width: auto;
    height: 30px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e7e7e7;
    background-color: #ffffff;
    font: normal normal 300 13px/27px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #00853F;
}

.addressApartment{
    text-align: left;
    font: normal normal 300 36px/40px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    margin-top: 10px;
}

.addressTitle{
    font: normal normal 300 18px/14px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
}

.priceApt{
    font: bold normal normal 28px/15px 'proxima-nova',sans-serif;
    letter-spacing: 0px;
    color: #00853F;
}

.flexCenter{
    display: flex;
    align-items: center;
    border-bottom: 2px solid #DFDFDF;
}

.iconPinMap{
    width: 49px;
    height: 59px;
}

.iconShareDet{
    background: url('../../images/icons/icona-share.svg');
    background-repeat: no-repeat;
    background-size: 33px 33px;
    height: 33px;
    width: 33px;
    background-position: center center;
    margin-right: 10px;
}

.iconPrintDet{
    background: url('../../images/icons/icona-print.svg');
    background-repeat: no-repeat;
    background-size: 33px 33px;
    height: 33px;
    width: 33px;
    background-position: center center;
}

.boxTitleMapDet{
    font-size: 20px;
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal normal 28px/32px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    border-right: 2px solid #ffffff;
    justify-content: right;
    padding: 0 30px;
}

.boxMapDescDet{
    width: 70%;
    padding: 0 30px;
}

.subdescMapDetMain{
    font: normal normal 500 16px/14px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 0;
    display: flex;
    align-items: center;
}

.subdescMapDet{
    font: normal normal 300 14px/14px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin: 0;
    display: flex;
    align-items: center;
}

.headerMapdDetail{
    width: 100%;
}

.backgroundMapDet{
    background-color: #F3F3F3;
}

.containerHeaderMapDet{
    background-color: #00853f;
    display: flex;
    justify-content: center;
    padding: 0;
    width: 100%;
    height: 570px;
    position: absolute;
}

.containerHeaderMapDetDx{
    background-color: red;
}

.containerHeaderMapDetSx{
    background-color: #3D3A3C;
}

.containerTitleMap{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    font: normal normal normal 28px/32px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 20px;
}

.containerMapDet{
    width: 95%;
    height: 470px;
    position: absolute;
    bottom: 0;
    padding-left: 100px;
}

.mainContainerMap{
    width: 100%;
    height: 570px;
    max-height: 570px;
    position: relative;
    margin: 0;
    top: -100px;
}

.styleEfficienza{
    font: normal normal normal 28px/32px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    width: max-content;
    margin: 0;
}

.rowEfficienza{
    background-color: #f3f3f3;
    padding: 70px 200px;
    padding-bottom: 150px;
}

.lineEfficienza{
    width: 40%;
    border-bottom: 2px solid #DFDFDF;
}

.labelMainEfficienza{
    font: normal normal 500 14px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
    width: 50%;
}

.labelTextEfficienza{
    font: normal normal 300 14px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
    width: 50%;
}

.descriptionDetail{
    margin-top: 25px;
    font: normal normal 300 14px/20px 'proxima-nova', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
    opacity: 0.86;
}

.mainLabelDetail{
    font: normal normal normal 28px/32px 'haboro-contrast-normal', sans-serif;
    letter-spacing: 0px;
    color: #3D3A3C;
}

.boxOtherImg{
    max-width: 200px;
    height: 200px;
    background-color: #00853f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    margin: 0 auto;
}
.photoValue{
    width: 100%;
    height: 100%;
    object-fit: cover;
}



@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){

    .btnDetailApp{
        font-size:11px;
    }

    .photoValue{
        max-width:175px !important;
    }

    .addressApartment{
        font: normal normal 300 30px/30px 'haboro-contrast-normal', sans-serif;
    }

    .addressTitle{
         font: normal normal 300 15px/14px 'proxima-nova', sans-serif;
    }

    .colDescDet.\32 .col-lg-6.col-md-6.col-sm-6.col-12 {
        border-right: none !important;
    }

    .rowDescDet.row{
        display: block;
    }

}


@media only screen and (max-device-width:767px){



    .breadcrumbDetail{
        font: normal normal normal 12px/15px 'proxima-nova',sans-serif;
        letter-spacing: 1.68px;
        opacity: 0.86;
    }

    .addressApartment{
        font: normal normal 300 25px/40px 'haboro-contrast-normal', sans-serif;
        margin-top: 10px;
        margin-bottom: 15px;
        opacity: 1;
    }
    .flexCenter{
        display:block;
    }
    .addressTitle{
        font: normal normal 300 14px/14px 'proxima-nova', sans-serif;
    }
     .containerPrice{
         float:left !important;
     }

     .boxIcons.col-lg-12.col-md-12.col-sm-12.col-12 {
        margin-top: 40px !important;
    }

    .priceApt{
        position: absolute;
        margin-top:14px;
        font:normal normal normal 19px/15px 'proxima-nova',sans-serif;
    }

    .photoValue{
        display:none;
    }

    .boxOtherImg {
        max-width: none !important;
        height: auto !important;
        justify-content: right;
        justify-content: right !important;
        padding-right: 8px;
        margin-top: -52px;
        background-color: transparent !important;
        font: normal normal normal 15px/13px 'Proxima-Nova',sans-serif;
    }

    .col-lg-6.col-md-6.col-sm-6.col-12{
        border-right: none !important;
     }

     .photoVet {
        border-radius: 4px;
        opacity: 1;
        top: 338px;
        left: 25px;
        width: 326px;
        height: 242px;
    }

    .btnDetailApp{
        font: normal normal 300 12px/27px 'proxima-nova', sans-serif;
    }

    .descDetails{
        font: normal normal 600 12px/15px 'proxima-nova',sans-serif !important;
        letter-spacing: 1.68px;
        text-transform: uppercase;
        opacity: 0.86;

    }

    .colDescDet.col-lg-6.col-md-6.col-sm-6.col-12 {
        margin-bottom: 25px;
    }

    .rowEfficienza{
        padding: 35px 23px;
        margin-bottom: 100px;
    }

    .styleEfficienza{
        font: normal normal normal 28px/32px 'haboro-contrast-normal', sans-serif;
    }

    .lineEfficienza{
        width:31px;
    }

    .labelMainEfficienza{
        width:50%;
        font:normal normal 500 13px/14px 'proxima-nova',sans-serif;
    }

    .labelTextEfficienza{
        width:25%;
        margin-left:34px;
    }

    .containerHeaderMapDet{
        height:auto;
        width:100%
    }

    .boxTitleMapDet{
        width:33%;
        padding: 0 16px;
        opacity:1;
    }

    .boxMapDescDet{
        width:67%;
        padding: 0 18px;
    }

    .subdescMapDetMain{
        font: normal normal 500 14px/20px 'proxima-nova', sans-serif;
    }

    .subdescMapDet{
        font: normal normal 300 11px/13px 'proxima-nova', sans-serif;
    }

    .containerMapDet{
        padding-left:0px;
        width:100%;
    }
}
