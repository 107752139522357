.boxInfoPopup{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    width: 45%;
    height: 65%;
    padding-top: 20px;
    border-radius: 11px;
    opacity: 1;
    overflow: auto;
}

.content{
    width: 100%;
}

/*.MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.9) ;
}*/

.boxInfoPopup:focus-visible{
   outline: none;
}
.close{
    position: absolute !important;
    right: 0;
    top: 0;
    float: right;
    color: white;
    align-items: center;
    justify-content: center;
    height: 17px;
    width: 17px;
    min-width: 0 !important;
    margin: 10px !important;
    padding: 20px !important;
    background-color: #d3d3d3 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}
#modal-modal-title{
    text-align: center;
    font-family: 'proxima-nova', sans-serif;
    font-weight: bold;
}

.location{
    display: flex;
    padding: 5px 10px 5px 10px;
    gap: 6px;
    align-items: center;
    justify-content: center;
    border: .5px solid #b6e2c9;
    border-radius: 4px;
    margin-left: 20%;
    margin-right: 20%;
}
.location span img {
    width: 13px;
    height: 16px;
    margin-bottom: 6px !important;
}

.location a{
    white-space: pre;
    margin-top: 4%;
    margin-left: 1%;
    margin-bottom: 15px !important;
    line-height: 0;
    font-size: 14px;
    color: #00853F;
    font-weight: 100;
}

#modal-modal-description1{
    text-align: center;
    font-family: 'haboro-contrast-normal', sans-serif;
    font-weight: 300;
}
#modal-modal-description2{
    text-align: center;
    font-family: 'haboro-contrast-normal', sans-serif;
    font-weight: bold;
    margin-bottom: 7%;
    line-height: 0;
}

.logo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
}

.logo_filcasa{
    height: 45px;
    width: auto;
}



@media (max-width: 767px){
    .boxInfoPopup {
    height: 45vh !important;
    width: 90vw !important;
    padding-top: inherit;
    }
    #modal-modal-title{
    margin-top:70px;
    }
    .location{
        margin-left: 5%;
        margin-right: 5%;
        font-size: 15px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px){
    .boxInfoPopup{
        width: 60% !important;
        height: 40%;
    }
    .location{
        margin-left: 12%;
        margin-right: 12%;
        font-size: 15px;
    }
    .logo{
        margin-top: 15%;
    }
}
